<template>
   <!-- cols="12" -->
   <v-col 
      v-if="(!modal.hidden || modal.hiddenLabel) && (($vuetify.breakpoint.mdAndUp && modal.laptopCols>0) || ($vuetify.breakpoint.smAndDown && modal.mobileCols>0))"
      :cols="$vuetify.breakpoint.mdAndUp?modal.laptopCols:modal.mobileCols"
      class="pa-1"
      :style="{'max-width': modal.width&&$vuetify.breakpoint.mdAndUp?modal.width:'none'}">
      <v-card class="pa-0">
         <v-card-title class="pa-0 pl-2 pr-0"
            :style="{'font-size': $vuetify.breakpoint.smAndUp?'14px':'14px'}"
            style="background-color:#898989;height:36px;word-break: keep-all;word-wrap: break-word;overflow-wrap:break-word;font-weight:700;color:white;line-height:1.2;border-bottom:1px solid #DDDDDD;">
            <v-spacer/>
            <div class="text-uppercase" style="color:white;">
               {{modal.displayName}}</div>
            <v-spacer/>
            <!--
            <v-btn v-if="modal.control"
               small icon dark
               @click="openSetterDialog">
               <v-icon 
                  :style="{
                     'font-size': $vuetify.breakpoint.mdAndUp?'16px':'16px',
                     'max-width': $vuetify.breakpoint.mdAndUp?'30px':'20px',
                     'margin-top': $vuetify.breakpoint.mdAndUp?'-4px':'-5px',
                     }"
                  color="yellow">mdi-hand-right</v-icon>
            </v-btn>
            -->
         </v-card-title>
         <v-card-text class="pa-0">
            <v-row class="ma-0 px-0" align="center" justify="center"
               :style="{
                  'min-height': modal.minHeight?modal.minHeight:'40px',
                  }"
               >
               <v-col align="center" justify="center" class='pa-0'>
                  <div v-if='modal.hidden && modal.hiddenLabel'
                     :style="{'color': modal.color||'#787878'}"
                     style='width:100%;height:100%;font-size:16px;' 
                     class='d-flex align-center justify-center'>
                     <span><b>{{modal.hiddenLabel}}</b></span>
                  </div>
                  <div v-else style="margin:auto;color:#2196F3;"
                     :style="{'color': modal.color||'#2196F3'}">
                     <div
                        @click="openSetterDialog"
                        :style="{
                           'font-size': textFontSize,
                           'line-height': contentHeight
                           }"
                        style="font-family:Verdana, sans-serif;font-weight:600;display:inline-block;vertical-align:top;">
                        <v-icon style="margin:-6px -2px 0 0;"
                           :style="{'font-size': $vuetify.breakpoint.mdAndUp?'30px':'22px'}"
                           color="#BABABA" >
                           {{modal.icon}}</v-icon>
                        <template v-if="modal.dataType && modal.dataType.includes('BOOL')">
                           <span v-if="modal.qos>=0 && modal.isOnline"
                              :style="{'color': modal.colors[modal.value]||'#2196F3'}"> 
                              {{modal.labels[modal.value]}}
                           </span>
                           <span v-else
                              :style="{'color': modal.colors[modal.value]||'#2196F3'}"> 
                              {{noValueText}}
                           </span>
                        </template>
                        <template v-else>
                           <span v-if="modal.qos>=0  && modal.isOnline">
                              {{modal.value}}
                           </span>
                           <span v-else> 
                              {{(modal.defaultValue?modal.defaultValue:noValueText)}}
                           </span>
                        </template>
                     </div>

                     <div v-if="modal.unit"
                        :style="{
                           'font-size': $vuetify.breakpoint.smAndUp?'14px':'12px',
                           'line-height': contentHeight
                           }"
                        style="padding-left:2px;font-weight:300;display:inline-block;vertical-align:top;">
                        {{modal.unit}}
                     </div>
                  </div>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
   </v-col>
</template>

<script>
export default {
   props: [
      'modal', 
      'customStyle',
      ],
   watch: {
      'modal.value': {
         handler () {
            this.$forceUpdate()
         },
         deep: true
      },
      'modal.qos': {
         handler () {
            this.$forceUpdate()
         },
         deep: true
      }
   },
   computed: {
      show: function () {
         if (this.mode.electronHide && this.$root.isElectron) return false
         if (this.mode.laptopHide && this.$vuetify.mdAndUp) return false
         if (this.mode.mobileHide && this.$vuetify.smAndDown) return false
         return true
      },
      textFontSize: function () {
         if(this.$vuetify.breakpoint.smAndUp) {
            return this.modal.valueFontSize || '26px'
         }
         if(this.$vuetify.breakpoint.smAndDown) {
            return this.modal.valueFontSizeMobile || '18px'
         }
         return "18px"
      },
      contentHeight: function () {
         if (this.$vuetify.breakpoint.lgAndUp) {
            return '80px'
         } else {
            return '54px'
         }
      }
   },
   data () {
      return {
         noValueText: ''
      }
   },
   methods: {
      openSetterDialog () {
         if (this.modal.control) {
            this.$emit('requestShowSetter', this.modal)
         }
      }
   }
}
</script>

<style>
   
</style>