var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((!_vm.modal.hidden || _vm.modal.hiddenLabel) && ((_vm.$vuetify.breakpoint.mdAndUp && _vm.modal.laptopCols>0) || (_vm.$vuetify.breakpoint.smAndDown && _vm.modal.mobileCols>0)))?_c('v-col',{staticClass:"pa-1",style:({'max-width': _vm.modal.width&&_vm.$vuetify.breakpoint.mdAndUp?_vm.modal.width:'none'}),attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp?_vm.modal.laptopCols:_vm.modal.mobileCols}},[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"pa-0 pl-2 pr-0",staticStyle:{"background-color":"#898989","height":"36px","word-break":"keep-all","word-wrap":"break-word","overflow-wrap":"break-word","font-weight":"700","color":"white","line-height":"1.2","border-bottom":"1px solid #DDDDDD"},style:({'font-size': _vm.$vuetify.breakpoint.smAndUp?'14px':'14px'})},[_c('v-spacer'),_c('div',{staticClass:"text-uppercase",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.modal.displayName))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0 px-0",style:({
               'min-height': _vm.modal.minHeight?_vm.modal.minHeight:'40px',
               }),attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"align":"center","justify":"center"}},[(_vm.modal.hidden && _vm.modal.hiddenLabel)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"100%","font-size":"16px"},style:({'color': _vm.modal.color||'#787878'})},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.modal.hiddenLabel))])])]):_c('div',{staticStyle:{"margin":"auto","color":"#2196F3"},style:({'color': _vm.modal.color||'#2196F3'})},[_c('div',{staticStyle:{"font-family":"Verdana, sans-serif","font-weight":"600","display":"inline-block","vertical-align":"top"},style:({
                        'font-size': _vm.textFontSize,
                        'line-height': _vm.contentHeight
                        }),on:{"click":_vm.openSetterDialog}},[_c('v-icon',{staticStyle:{"margin":"-6px -2px 0 0"},style:({'font-size': _vm.$vuetify.breakpoint.mdAndUp?'30px':'22px'}),attrs:{"color":"#BABABA"}},[_vm._v(" "+_vm._s(_vm.modal.icon))]),(_vm.modal.dataType && _vm.modal.dataType.includes('BOOL'))?[(_vm.modal.qos>=0 && _vm.modal.isOnline)?_c('span',{style:({'color': _vm.modal.colors[_vm.modal.value]||'#2196F3'})},[_vm._v(" "+_vm._s(_vm.modal.labels[_vm.modal.value])+" ")]):_c('span',{style:({'color': _vm.modal.colors[_vm.modal.value]||'#2196F3'})},[_vm._v(" "+_vm._s(_vm.noValueText)+" ")])]:[(_vm.modal.qos>=0  && _vm.modal.isOnline)?_c('span',[_vm._v(" "+_vm._s(_vm.modal.value)+" ")]):_c('span',[_vm._v(" "+_vm._s((_vm.modal.defaultValue?_vm.modal.defaultValue:_vm.noValueText))+" ")])]],2),(_vm.modal.unit)?_c('div',{staticStyle:{"padding-left":"2px","font-weight":"300","display":"inline-block","vertical-align":"top"},style:({
                        'font-size': _vm.$vuetify.breakpoint.smAndUp?'14px':'12px',
                        'line-height': _vm.contentHeight
                        })},[_vm._v(" "+_vm._s(_vm.modal.unit)+" ")]):_vm._e()])])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }